/* eslint-disable jsx-quotes */
import { useState, useRef } from 'react';
import { Form, Input } from 'antd';
import Image from 'next/image';
import {
  actionLogin,
  actionRegister,
  actionResendOTPRegister,
  actionVerifyOtp,
  actionSaveInfoUser,
  actionSendAccount,
  actionChangePassword,
  actionSendOtpForgotPassword,
} from '../redux/actions/auth';
import { getLast } from '../redux/actions/order_cart';
import useTrans from '@/hooks/useTrans';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
const Login = props => {
  const trans = useTrans();
  const regexCheckName = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
  const [messageError, setMessageError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReg, setShowPasswordReg] = useState(false);
  const [showPasswordReg2, setShowPasswordReg2] = useState(false);
  const [input_password, setPassword] = useState('');
  const [input_password2, setPassword2] = useState('');
  const [activeInputConfirmPass, setActiveInputConfirmPass] = useState(false);
  const [changeForgotPassword, setForgotChangePassword] = useState(false);
  const [accountUser, setAccountUser] = useState(null);
  const [activeState, setActiveState] = useState(0);

  const formRef = useRef();
  const formReg = useRef();
  const formConfirm = useRef();
  const formOtp = useRef();
  const formSendAccount = useRef();
  const state = props.state || 0;

  const dispatch = useDispatch();

  const { infoUser, user_ref } = useSelector(state => {
    return {
      infoUser: state.auth.infoUser,
      user_ref: state.user.user_ref,
    };
  }, shallowEqual);
  const clickClose = () => {
    const formData = formRef.current;
    const formDataReg = formReg.current;
    const formDataConfirm = formConfirm.current;
    const formDataOtp = formOtp.current;
    formData.resetFields();
    formDataReg.resetFields();
    formDataConfirm.resetFields();
    formDataOtp.resetFields();

    if (props.setState) {
      props.setState(0);
    }
  };

  const onSuccess = async () => {
    setShowSuccess(false);
    if (props.setState) {
      props.setState(0);
    }
    if (localStorage.getItem('token')) {
      await dispatch(actionSaveInfoUser());
    }

    const formData = formRef.current;
    const formDataReg = formReg.current;
    const formDataConfirm = formConfirm.current;
    const formDataOtp = formOtp.current;
    formData.resetFields();
    formDataReg.resetFields();
    formDataConfirm.resetFields();
    formDataOtp.resetFields();
    setPassword('');
    setPassword2('');
  };
  const onConfirm = async () => {
    const formData = formOtp.current.getFieldValue();
    const dataSubmit = {
      otp: formData?.otp?.trim(),
      token: localStorage.getItem('tokenReg'),
    };
    const res = await actionVerifyOtp(dataSubmit);
    if (res) {
      showError(res);
    } else {
      localStorage.removeItem('tokenReg');
      setShowSuccess(true);
      props.setState(0);
    }
  };

  // console.log('accountUser', accountUser?.indexOf('@'));
  // Hàm gửi lại mã OTP lấy lại mật khẩu

  const reSendOtpForgotPassword = async () => {
    const { otp_forgot } = formSendAccount.current.getFieldValue();
    const sendOtp = await actionSendAccount({ account: accountUser });
    console.log('data', sendOtp);
    if (sendOtp?.code === 200) {
      showError('Gửi thành công');
    } else {
      showError(sendOtp.message);
    }
  };

  const reSendOtp = async () => {
    const sendOtp = await actionResendOTPRegister();
    if (!sendOtp) {
      showError('Gửi thành công');
    } else {
      showError(sendOtp);
    }
  };

  // hàm Đăng nhập
  const clickLogin = async () => {
    await formRef?.current?.validateFields();
    const formData = formRef?.current?.getFieldValue();
    const dataSubmit = {
      account: formData?.account.trim(),
      password: formData?.password,
    };
    const res = await actionLogin(dataSubmit);
    if (res) {
      return showError(res);
    }
    if (localStorage.getItem('token')) {
      await dispatch(actionSaveInfoUser());
      await getLast({}, dispatch);
    }
    if (props.setState) {
      props.setState(0);
    }
  };

  // hàm xác thực và gửi mã OTP đi
  const submitOTP = async () => {
    await formSendAccount.current.validateFields();
    const { otp_forgot } = formSendAccount.current.getFieldValue();
    const dataSubmit = {
      account: accountUser,
      otp: otp_forgot,
    };
    const res = await actionSendOtpForgotPassword(dataSubmit);
    // console.log('res', res);
    if (res?.code === 200) {
      setActiveState(2);
    } else {
      showError(res.message);
    }
    console.log(res.message);
  };
  // Hàm tắt form đăng nhập và hiện from lấy lại mật khẩu
  const forgotPassWord = () => {
    setActiveInputConfirmPass(true);
    clickClose();
  };
  // Hàm gửi tài khoản lấy lại mật khẩu
  const sendAccount = async () => {
    await formSendAccount?.current?.validateFields();
    const { account_forgot } = formSendAccount?.current?.getFieldValue();
    setAccountUser(account_forgot);
    const res = await actionSendAccount({ account: account_forgot }, dispatch);
    if (res?.code === 200) {
      setActiveState(1);
    } else {
      showError(res?.message);
    }
  };

  const showError = message => {
    setMessageError(message);
    setTimeout(() => {
      setMessageError('');
    }, 3000);
  };

  const submitReg = async () => {
    await formReg.current.validateFields();
    const formData = formReg.current.getFieldValue();
    if (
      !formData?.name?.trim() ||
      (useEmail && !emailReg.trim()) ||
      (!useEmail && !mobileReg.trim()) ||
      !input_password2 ||
      !input_password
    ) {
      showError('Vui lòng nhập đầy đủ thông tin!');
      return;
    }
    if (input_password2 !== input_password) {
      showError(trans.message.val_e);
      return;
    }

    const dataSubmit = {
      email: emailReg || '',
      mobile: mobileReg || '',
      fullname: formData?.name?.trim(),
      ref_code: user_ref && user_ref.ref_code || '',
      password: input_password,
    };
    const res = await actionRegister(dataSubmit);
    if (res) {
      showError(res);
    } else {
      props.setState(3);
    }
  };

  // Hàm đổi mật khẩu
  const changePassword = async () => {
    await formSendAccount?.current?.validateFields();
    const {
      password_confirm,
      new_password,
    } = formSendAccount?.current?.getFieldValue();
    if (!password_confirm || !new_password) {
      showError(trans.message.val_d);
    } else if (password_confirm.trim() !== new_password.trim()) {
      showError(trans.message.val_e);
    } else {
      let dataSubmit = {
        password: new_password.trim(),
        token: localStorage.getItem('tokenReg'),
      };
      const res = await actionChangePassword(dataSubmit, dispatch);
      if (res.code === 200) {
        setActiveInputConfirmPass(false);
        setActiveState(3);
        setForgotChangePassword(new_password.trim());
      } else {
        showError(res.message);
      }
    }
  };

  // Hàm đóng thông báo đổi mật khẩu thành công
  const onCloseModalMessageChangePass = () => {
    setActiveState(0);
    setActiveInputConfirmPass(false);
    setShowSuccess(false);
  };

  const onSubmit = number => {
    switch (number) {
      case 0:
        return clickLogin(); // login
      case 1:
        console.log('case 1');
        return sendAccount(); // gửi tài khoản lên
      case 2:
        console.log('case 2');
        return submitOTP(); // nhập mã OTP và nhận về token
      case 3:
        console.log('case 3');
        return changePassword(); // đẩy mật khẩu mới và token vừa nhận về lên server
      case 4:
        console.log('case 4');
        return onCloseModalMessageChangePass();
      default:
        break;
    }
  };

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };
  const validateMobile = inputtxt => {
    const phoneno = /^(\+[0-9]{2})?[\d]+$/;
    if (inputtxt?.match(phoneno)) return true;
    return false;
  };
  // console.log('validateMobile', validateMobile('0342562356'));
  const enterKeyPressed = event => {
    if (event.keyCode === 13) {
      clickLogin();
    }
  };
  const [errorPass, setErrorPass] = useState(false);
  const [useEmail, setUseEmail] = useState(true);
  const [mobileReg, setMobileReg] = useState('');
  const [emailReg, setEmailReg] = useState('');

  const onChangeOtp = value => {
    formSendAccount.current.setFieldsValue({
      otp_forgot: value.replace(/[^0-9]+/, ''),
    });
  };

  return (
    <div>
      <div
        className={
          state === 1 ? 'c-modal-account' : 'c-modal-account is-hidden-modal'
        }
        id="c-modal-login"
      >
        <div className="c-modal-account__transaction" />
        <div className="c-modal-account__inner">
          <div className="c-modal-account__inner__form-login c-modal-account__inner__padding">
            <div onClick={clickClose} className="c-modal-account__inner__close">
              <img
                className="bct"
                src="/assets/img/common/icn_close_02.svg"
                alt=""
              />
            </div>
            <img
              className="bct"
              src="/assets/img/common/fig_logo_03.svg?t=1"
              alt=""
            />
            <h2>{trans?.login.log}</h2>
            <p>
              {trans?.login.log_p}{' '}
              <a
                className="btn-dk1"
                onClick={() => {
                  props.setState(2);
                }}
              >
                {trans?.login.re}
              </a>
            </p>
            <p style={{ color: 'red', height: '24px' }}>{messageError}</p>
            <div className="c-modal-account__inner__form-login__form">
              <Form ref={formRef}>
                <div className="c-modal-account__inner__form-login__form__input">
                  <Form.Item
                    name="account"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value.trim() || value.trim() === '')
                            return Promise.reject(trans.message.val_n);
                          if (!validateEmail(value) && !validateMobile(value))
                            return Promise.reject(trans.message.val_o);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <input
                      name="account"
                      type="text"
                      placeholder={trans.message.pla_n}
                    />
                  </Form.Item>
                </div>
                <div className="c-modal-account__inner__form-login__form__input">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            setErrorPass(true);
                            return Promise.reject(trans.message.val_d);
                          }
                          setErrorPass(false);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <input
                      onKeyDown={enterKeyPressed}
                      name="password"
                      id="password-field3"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={trans.message.pla_o}
                    />
                  </Form.Item>
                  <span
                    className={
                      errorPass
                        ? 'toggle-password toggle-password-error'
                        : 'toggle-password'
                    }
                  >
                    <img
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      className="bct"
                      src={
                        showPassword
                          ? '/assets/img/common/icn_eye_open_01.svg'
                          : '/assets/img/common/icn_eye_close_01.svg'
                      }
                      alt=""
                    />
                  </span>
                </div>
                <div className="c-modal-account__inner__form-login__form__checkbox">
                  <label className="c-checkbox">
                    {' '}
                    {trans.login.remem}
                    <input type="checkbox" />
                    <span className="c-checkbox__checkmark" />
                  </label>
                </div>
                <div className="c-modal-account__inner__form-login__form__btn">
                  <a className="c-btn__primary" onClick={() => onSubmit(0)}>
                    {trans.login.log}
                  </a>
                </div>
                <div className="forget-pass" onClick={() => forgotPassWord()}>
                  {trans.login.forgot}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal đăng nhập */}
      {/* Modal đăng ký */}
      <div
        className={
          state === 2 ? 'c-modal-account' : 'c-modal-account is-hidden-modal'
        }
        id="c-modal-signin"
      >
        <div className="c-modal-account__transaction" />
        <div className="c-modal-account__inner">
          <Form ref={formReg}>
            <div className="c-modal-account__inner__form-login">
              <div onClick={clickClose} className="c-modal-account__inner__close">
                <img
                  className="bct"
                  src="/assets/img/common/icn_close_02.svg"
                  alt=""
                />
              </div>
              <img
                className="bct"
                src="/assets/img/common/fig_logo_03.svg?t=1"
                alt=""
              />
              <h2>{trans.login.re_a}</h2>
              {user_ref && !infoUser ? (
                <div className="p-app-account__inner__invitation-from">
                  <h5>{trans.login.text}</h5>
                  <a className="p-app-account__inner__invitation-from__box">
                    <img
                      className="avatar-ref"
                      src={
                        user_ref.avatar
                          ? user_ref.avatar
                          : `https://ui-avatars.com/api/?name=${user_ref.fullname}`
                      }
                      alt=""
                    />
                    <p>{user_ref.fullname}</p>
                    <Form.Item name="ref_code" initialValue={user_ref.ref_code}>
                      <Input value={user_ref.ref_code} type="hidden" />
                    </Form.Item>
                  </a>
                </div>
              ) : (
                false
              )}
              {!user_ref ? (
                <p>
                  {trans.login.re_b}{' '}
                  <a
                    className="btn-dn1"
                    onClick={() => {
                      props.setState(1);
                    }}
                  >
                    {trans.login.log_b}
                  </a>
                </p>
              ) : (
                false
              )}
              <p style={{ color: 'red', height: '24px' }}>{messageError}</p>
              <div className="c-modal-account__inner__form-login__form">
                <div className="c-modal-account__inner__form-login__form__input">
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value)
                            return Promise.reject(trans.message.val_m);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <input
                      type="text"
                      placeholder={trans.message.pla_a}
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>

                <div className="c-modal-account__inner__form-login__form__input email">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        validator: (_, _value) => {
                          if (!emailReg)
                            return Promise.reject(trans.message.val_b);
                          if (
                            !emailReg.match(
                              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g,
                            )
                          )
                            return Promise.reject(trans.message.val_a);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <input
                      type="text"
                      value={emailReg}
                      onChange={e => {
                        setEmailReg(e.target.value);
                        formReg.current.validateFields(['email']);
                      }}
                      placeholder={trans.message.pla_d}
                      autoComplete="off"
                    />
                    {/* <span
                      className="toggle-mobile-phone"
                      onClick={() => {
                        setUseEmail(false);
                        setEmailReg('');
                      }}
                    >
                      {' '}
                      | Sử dụng số điện thoại
                    </span> */}
                  </Form.Item>
                </div>
                <div className="c-modal-account__inner__form-login__form__input mobile">
                  <Form.Item
                    name="mobile"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!mobileReg)
                            return Promise.reject(trans.message.val_c);
                          if (!validateMobile(mobileReg))
                            return Promise.reject(
                              'Sai định dạng số điện thoại!',
                            );
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <input
                      type="text"
                      placeholder={trans.message.pla_e}
                      autoComplete="off"
                      value={mobileReg}
                      onChange={e => {
                        setMobileReg(e.target.value);
                        formReg.current.validateFields(['mobile']);
                      }}
                    />
                    {/* <span
                      className="toggle-mobile-phone"
                      onClick={() => {
                        setUseEmail(true);
                        setMobileReg('');
                      }}
                    >
                      {' '}
                      | Sử dụng Email
                    </span> */}
                  </Form.Item>
                </div>

                <div className="iqabsadbbqbwa">
                  <div className="c-modal-account__inner__form-login__form__input email">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!input_password || input_password === '')
                              return Promise.reject(trans.message.val_d);
                            if (input_password && input_password.length < 8)
                              return Promise.reject(
                                'Mật khẩu phải nhiều hơn 8 kí tự!',
                              );
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <input
                        id="password-field1"
                        type={showPasswordReg ? 'text' : 'password'}
                        autoComplete="off"
                        placeholder={trans.message.pla_o}
                        value={input_password.trim()}
                        onChange={async e => {
                          setPassword(e.target.value);
                          await formReg.current.validateFields(['password']);
                        }}
                      />
                      <span className="toggle-password">
                        <img
                          onClick={async () => {
                            setShowPasswordReg(!showPasswordReg);
                          }}
                          className="bct"
                          src={
                            showPasswordReg
                              ? '/assets/img/common/icn_eye_open_01.svg'
                              : '/assets/img/common/icn_eye_close_01.svg'
                          }
                          alt=""
                        />
                      </span>
                    </Form.Item>
                  </div>
                  <div className="c-modal-account__inner__form-login__form__input email">
                    <Form.Item
                      name="password2"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!input_password2 || input_password2 === '')
                              return Promise.reject(
                                'Chưa nhập mật khẩu xác thực!',
                              );
                            if (input_password2 !== input_password)
                              return Promise.reject(trans.message.val_e);
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <input
                        id="password-field2"
                        type={showPasswordReg2 ? 'text' : 'password'}
                        autoComplete="off"
                        placeholder={trans.message.pla_p}
                        value={input_password2.trim()}
                        onChange={async e => {
                          setPassword2(e.target.value);
                          await formReg.current.validateFields(['password2']);
                        }}
                      />
                      <span className="toggle-password">
                        <img
                          onClick={() => {
                            setShowPasswordReg2(!showPasswordReg2);
                          }}
                          className="bct"
                          src={
                            showPasswordReg2
                              ? '/assets/img/common/icn_eye_open_01.svg'
                              : '/assets/img/common/icn_eye_close_01.svg'
                          }
                          alt=""
                        />
                      </span>
                    </Form.Item>
                  </div>
                </div>
                {/* <div className="c-modal-account__inner__form-login__form__input ref">
                  <Form.Item name="ref_code">
                    <input
                      type="text"
                      placeholder="Mã giới thiệu"
                      autoComplete="off"
                    />
                  </Form.Item>
                </div> */}
                <div className="c-modal-account__inner__form-login__form__checkbox">
                  <label className="c-checkbox">
                    {' '}
                    {trans.login.remem}
                    <input type="checkbox" />
                    <span className="c-checkbox__checkmark" />
                  </label>
                </div>
                <div className="c-modal-account__inner__form-login__form__btn">
                  <a
                    className="c-btn__primary"
                    onClick={() => {
                      submitReg();
                    }}
                  >
                    {trans.login.create}
                  </a>
                </div>
              </div>
              {/* <p className="p-app-account__term">
                *Khi đăng kí bạn đã đồng ý với{' '}
                <Link href="/policy">
                  <a
                    onClick={() => {
                      props.setState(0);
                    }}
                    href="/policy"
                  >
                    điều khoản sử dụng
                  </a>
                </Link>{' '}
                &amp;{' '}
                <Link href="/security">
                  <a
                    onClick={() => {
                      props.setState(0);
                    }}
                    href="/security"
                  >
                    chính sách quyền riêng tư
                  </a>
                </Link>
                .
              </p> */}
            </div>
          </Form>
        </div>
      </div>
      {/* End Modal đăng nhập */}
      {/* Modal đăng ký */}
      <div
        className={
          state === 3 ? 'c-modal-account' : 'c-modal-account is-hidden-modal'
        }
        id="c-modal-passport"
      >
        <div className="c-modal-account__transaction" />
        <div className="c-modal-account__inner">
          <div className="c-modal-account__inner__form-login c-modal-account__inner__form-pass">
            <Form ref={formOtp}>
              <div onClick={clickClose} className="c-modal-account__inner__close">
                <img
                  className="bct"
                  src="/assets/img/common/icn_close_02.svg"
                  alt=""
                />
              </div>
              <img
                className="bct"
                src="/assets/img/common/fig_logo_03.svg?t=1"
                alt=""
              />
              <h2>{trans.login.code}</h2>
              <p>{trans.login.code_text}</p>

              <p style={{ color: 'red', height: '21px' }}>{messageError}</p>
              <div className="c-modal-account__inner__form-login__form">
                <div className="c-modal-account__inner__form-login__form__input">
                  <Form.Item name="otp" required>
                    <input
                      maxLength={6}
                      type="text"
                      placeholder={trans.message.pla_r}
                    />
                  </Form.Item>
                </div>
                <div className="c-modal-account__inner__form-login__form__btn">
                  <a
                    className="c-btn__primary"
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    {trans.login.suc}
                  </a>
                </div>
                <div className="p-app-account__inner__form__button">
                  <a
                    onClick={() => {
                      reSendOtp();
                    }}
                  >
                    {trans.login.re_send}
                  </a>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div
        className={
          showSuccess ? 'c-modal-account' : 'c-modal-account is-hidden-modal'
        }
        id="c-modal-passport"
      >
        <div className="c-modal-account__transaction" />
        <div className="c-modal-account__inner">
          <div className="c-modal-account__inner__form-login c-modal-account__inner__form-pass">
            <Form ref={formConfirm}>
              <div onClick={onSuccess} className="c-modal-account__inner__close">
                <img
                  className="bct"
                  src="/assets/img/common/icn_close_02.svg"
                  alt=""
                  layout="fill"
                />
              </div>
              <img
                className="bct danbwqdad"
                src="/assets/img/common/new_lo.svg"
                alt=""
                layout="fill"
              />
              <h2>{trans.login.suc_b}</h2>
              <p
                style={{ maxWidth: '461px', margin: 'auto' }}
                dangerouslySetInnerHTML={{
                  __html: trans.login.text_b,
                }}
              ></p>
              <p style={{ color: 'red' }}>{messageError}</p>
              <div className="c-modal-account__inner__form-login__form">
                <div className="c-modal-account__inner__form-login__form__btn">
                  <a
                    className="c-btn__primary nangnanfsnafnfn_mb"
                    href="https://onelink.to/73mc23"
                    target="_blank"
                  >
                    {trans.login.down}
                  </a>

                  <a
                    className="c-btn__primary nangnanfsnafnfn_dt"
                    href="/app-mua-vang-online"
                    target="_blank"
                  >
                    {trans.login.more}
                  </a>
                </div>
              </div>
              <div className="qr_logo hdhahwhahdashda">
                <div className="pay_a pay_a_b">
                  <span style={{ marginBottom: '5.58px' }}>
                    {' '}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.hana.gold"
                      target="_blank"
                      className="relative"
                    >
                      <img
                        src="/assets/img/common/gg_p.webp"
                        alt=""
                        layout="fill"
                      />
                      <p className="absolute">Get it on Google Play</p>
                    </a>
                  </span>
                  <span>
                    {' '}
                    <a
                      href="https://apps.apple.com/vn/app/hanagold/id1611748758"
                      target="_blank"
                      className="relative"
                    >
                      <img
                        src="/assets/img/common/app_t.png"
                        alt=""
                        layout="fill"
                      />
                      <p className="absolute">Download on the App Store</p>
                    </a>
                  </span>
                </div>
                <div className="qr_a qr_a_b">
                  <img
                    src="/assets/img/common/hng_qrcode.svg"
                    alt=""
                    layout="fill"
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {/* <section
        className="c-app-account-done"
        style={showSuccess ? { display: 'block' } : { display: 'none' }}
      >
        <div className="c-app-account-done__transaction" />
        <div className="c-app-account-done__inner">
          <img src="/assets/img/app-signin/icn_done.svg" alt="" />
          <h2>{'Tạo tài khoản'} thành công!</h2>
          <p>
            Chúc mừng bạn!
            <br /> tài khoản đã được tạo.
          </p>
          <a
            onClick={() => {
              onSuccess();
            }}
          >
            Quay lại trang chủ
          </a>
        </div>
      </section> */}

      {/* Thông báo đổi mật khẩu thành công */}
      {activeState === 3 && (
        <section className="c-app-account-done" style={{ display: 'block' }}>
          <div className="c-app-account-done__transaction" />
          <div className="c-app-account-done__inner">
            <img src="/assets/img/app-signin/icn_done.svg" alt="" />
            <h2>Đổi mật khẩu thành công!</h2>
            {/* <p>Mật khẩu mới là: "{changeForgotPassword}"</p> */}
            <a onClick={() => onSubmit(4)}>Quay lại trang chủ</a>
          </div>
        </section>
      )}
      {/* Modal LẤY LẠI MẬT KHẨU */}
      {activeInputConfirmPass && (
        <div className="c-modal-account" id="c-modal-passport">
          <div className="c-modal-account__transaction" />
          <div className="c-modal-account__inner">
            <div className="c-modal-account__inner__form-login c-modal-account__inner__form-pass">
              <Form ref={formSendAccount}>
                <div
                  onClick={() => {
                    setActiveInputConfirmPass(false);
                    setActiveState(0);
                  }}
                  className="c-modal-account__inner__close"
                >
                  <img
                    className="bct"
                    src="/assets/img/common/icn_close_02.svg"
                    alt=""
                  />
                </div>
                <img
                  className="bct"
                  src="/assets/img/common/fig_logo_03.svg?t=1"
                  alt=""
                />
                <h2>{trans.login.p_a}</h2>
                <p style={activeState === 0 ? {} : { display: 'none' }}>
                  {trans.login.p_b}
                </p>
                <p style={activeState === 1 ? {} : { display: 'none' }}>
                  {trans.login.p_c}
                </p>
                <p style={{ color: 'red' }}>{messageError}</p>
                <div className="c-modal-account__inner__form-login__form">
                  {activeState === 0 && (
                    <div className="c-modal-account__inner__form-login__form__input">
                      <Form.Item
                        name="account_forgot"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value?.trim() === '' || !value) {
                                return Promise.reject(
                                  'Vui lòng nhập số điện thoại hoặc email đăng ký!',
                                );
                              }
                              if (
                                !validateEmail(value) &&
                                validateMobile(value) === false
                              ) {
                                return Promise.reject(
                                  'Sai định dạng tài khoản!',
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <input
                          maxlength={50}
                          type="text"
                          placeholder={trans.message.pla_n}
                        />
                      </Form.Item>
                    </div>
                  )}

                  {activeState === 1 && (
                    <div className="c-modal-account__inner__form-login__form__input">
                      <Form.Item
                        name="otp_forgot"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (!value)
                                return Promise.reject(trans.message.val_p);
                              if (value.length < 6)
                                return Promise.reject(trans.message.val_q);
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <input
                          type="text"
                          maxLength={6}
                          placeholder={trans.message.pla_s}
                          onChange={e => onChangeOtp(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )}

                  {activeState === 2 && (
                    <div>
                      <div className="c-modal-account__inner__form-login__form__input">
                        <Form.Item
                          name="new_password"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                  return Promise.reject(
                                    'Chưa nhập mật khẩu mới!',
                                  );
                                } else if (value.trim().length < 8) {
                                  return Promise.reject(
                                    'Mật khẩu phải có nhiều  hơn 8 kí tự!',
                                  );
                                } else if (
                                  value.indexOf(' ') === 0 ||
                                  value[value.length - 1] === ' '
                                ) {
                                  return Promise.reject(
                                    'Thừa dấu cách ở trước và sau mật khẩu!',
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <input
                            maxlength={50}
                            type="text"
                            placeholder={trans.message.pla_q}
                          />
                        </Form.Item>
                      </div>
                      <div className="c-modal-account__inner__form-login__form__input">
                        <Form.Item
                          name="password_confirm"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                  return Promise.reject(
                                    'Chưa nhập mật khẩu xác thực!',
                                  );
                                } else if (value.trim().length < 8) {
                                  return Promise.reject(
                                    'Mật khẩu phải có nhiều  hơn 8 kí tự!',
                                  );
                                } else if (
                                  value.indexOf(' ') === 0 ||
                                  value[value.length - 1] === ' '
                                ) {
                                  return Promise.reject(
                                    'Thừa dấu cách ở trước và sau mật khẩu!',
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <input
                            maxlength={50}
                            type="text"
                            placeholder={trans.message.pla_o}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {activeState === 0 && (
                    <div className="c-modal-account__inner__form-login__form__btn">
                      <a className="c-btn__primary" onClick={() => onSubmit(1)}>
                        {trans.login.p_d}
                      </a>
                    </div>
                  )}

                  {activeState === 1 && (
                    <div className="c-modal-account__inner__form-login__form__btn">
                      <a className="c-btn__primary" onClick={() => onSubmit(2)}>
                        {trans.login.p_e}
                      </a>
                    </div>
                  )}
                  {activeState === 2 && (
                    <div className="c-modal-account__inner__form-login__form__btn">
                      <pre>
                        <a
                          className="c-btn__primary"
                          onClick={() => onSubmit(3)}
                        >
                          {trans.login.p_f}
                        </a>
                      </pre>
                    </div>
                  )}
                  {activeState === 1 && (
                    <div className="p-app-account__inner__form__button">
                      <a
                        onClick={() => {
                          reSendOtpForgotPassword();
                        }}
                      >
                        {trans.login.p_g}
                      </a>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
