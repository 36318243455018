/* eslint-disable jsx-quotes */
import Link from 'next/link';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import Login from './Login';
import { actionLogout, actionSaveShowPopupLogin } from '../redux/actions/auth';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getLast } from '../redux/actions/order_cart';
import { updateOrderCart } from '../redux/actions/order_cart';
import Image from 'next/image';
import useTrans from '../hooks/useTrans';
('next/router');

const Header = props => {
  const trans = useTrans();
  const thisRefSettingAccount = useRef(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const { offset } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({});
  const [checkHeader, setCheckHeader] = useState('');
  const [showSettingAccount, setShowSettingAccount] = useState(false);
  const [pathName, setPathName] = useState("");

  const changeLanguageVi = () => {
    router.push('/', '/', { locale: 'vi' });
  };
  useEffect(()=>{
    const _url = window.location.pathname;
    setPathName(_url);
  }, [router.pathname]);

  useEffect(() => {
    getLast({}, dispatch);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('click', onCickOutShowSettingAccount);
    return () => {
      window.removeEventListener('click', onCickOutShowSettingAccount);
    };
  }, []);

  const changeLanguageEn = () => {
    router.push('/', '/', { locale: 'en' });
  };

  const { showLogin } = useSelector(state => {
    return {
      showLogin: state.auth.showLogin,
    };
  }, shallowEqual);

  const setState = show_id => {
    dispatch(actionSaveShowPopupLogin(show_id));
  };

  const onLogout = async () => {
    actionLogout(dispatch);
    setShowSettingAccount(false);
    await dispatch(updateOrderCart());
  };

  const onCickShowSettingAccount = () => {
    setShowSettingAccount(!showSettingAccount);
  };
  const onCickOutShowSettingAccount = event => {
    const { target } = event;
    if (
      thisRefSettingAccount &&
      thisRefSettingAccount.current &&
      !thisRefSettingAccount.current.contains(target)
    ) {
      setShowSettingAccount(false);
    }
  };

  const clickLinkMobile = async url => {
    await setOpenMenu(false);
    router.push(url);
  };

  const clickOpenMenu = open => {
    setOpenMenu(open);
    setOpenSubMenu('');
  };

  const { infoUser, carts } = useSelector(state => {
    return {
      infoUser: state.auth.infoUser,
      carts: state.cart.carts,
    };
  }, shallowEqual);

  const onClickCheckOut = () => {
    if (!infoUser) {
      dispatch(actionSaveShowPopupLogin(1));
    } else {
      window.location.href = '/dashboard/checkout';
    }
  };

  const arr_header = [
    {
      title: trans?.header?.solution,
      path: '',
      sub_header: [
        {
          title: trans?.header?.tech_gold,
          path: '/dong-vang-cong-nghe-hanagold',
          sub_header: [],
        },
        {
          title: trans?.header?.mobile_app,
          path: '/app-mua-vang-online',
          sub_header: [],
        },
        {
          title: trans?.header?.happy_stone,
          path: '/trang-suc-happy-stone',
          sub_header: [],
        },
        { 
          title: trans?.header?.gift,
          path: '',
          sub_header: [
            {
              title: trans?.header?.picture,
              path: '/tranh-ma-vang',
              sub_header: [],
            },
            {
              title: trans?.header?.statue,
              path: '/tuong-phong-thuy',
              sub_header: [],
            },
          ],
        },
        {
          title: trans?.header?.project,
          path: '',
          sub_header: [
            {
              title: trans?.header?.gold_tap_tap,
              path: '/gold-tap-tap',
              sub_header: [],
            },
            {
              title: trans?.header?.gold_atm,
              path: 'https://hanagold.sg',
              sub_header: [],
            },
          ],
        },
      ],
    },
    {
      title: trans?.header?.about,
      path: '',
      sub_header: [
        {
          title: trans?.header?.intro,
          path: '/gioi-thieu',
          sub_header: [],
        },
        {
          title: trans?.header?.partner_a,
          path: '/doi-tac',
          sub_header: [],
        },
        {
          title: trans?.header?.cer,
          path: '/giay-phep-giay-chung-nhan-hanagold',
          sub_header: [],
        },

        {
          title: trans?.header?.brand,
          path: 'https://brand.hanagold.vn/',
          other: 1,
          sub_header: [],
        },
      ],
    },
    {
      title: trans?.header?.partner_b,
      path: '',
      sub_header: [
        {
          title: trans?.header?.affiliate,
          path: '/dai-su-kinh-doanh-vang',
          sub_header: [],
        },
        {
          title: trans?.header?.franchise,
          path: '/nhuong-quyen-kinh-doanh-vang',
          sub_header: [],
        },
      ],
    },
    {
      title: trans?.header?.market,
      path: '',
      sub_header: [
        {
          title: trans?.header?.gold_price,
          path: '/gia-vang',
          sub_header: [],
        },
        {
          title: trans?.header?.rate,
          path: '/ty-gia-ngoai-te',
          sub_header: [],
        },
      ],
    },
    {
      title: trans?.header?.news,
      path: '/tin-tuc-su-kien',
      sub_header: [
        {
          title: trans?.header?.gold_market,
          path: '/thi-truong-vang',
          sub_header: [],
        },
        {
          title: trans?.header?.sale,
          path: '/chuong-trinh-uu-dai',
          sub_header: [],
        },
        {
          title: trans?.header?.mess,
          path: '/ban-tin',
          sub_header: [],
        },
        {
          title: trans?.header?.noti,
          path: '/thong-bao',
          sub_header: [],
        },
        {
          title: trans?.header?.knowledge,
          path: '/kien-thuc-dau-tu',
          sub_header: [],
        }
      ],
    },
    {
      title: trans?.header?.support,
      path: '',
      sub_header: [
        {
          title: trans?.header?.instructions,
          path: '/huong-dan-su-dung',
          sub_header: [],
        },
        {
          title: trans?.header?.tele,
          path: 'https://zalo.me/vanghanagold',
          other: 1,
          sub_header: [],
        },
      ],
    },
  ];

  return (
    <div>
      <header
        className="l-header-mobile"
        style={
          router.pathname &&
          [
            '/dai-su-kinh-doanh-vang',
            '/app-mua-vang-online',
            '/dong-vang-cong-nghe-hanagold',
            '/doi-tac',
            '/gia-vang',
            '/nhuong-quyen-kinh-doanh-vang',
            '/ty-gia-ngoai-te',
            '/nhuong-quyen-kinh-doanh-vang/[id]',
          ].includes(router.pathname)
            ? {
                position: 'absolute',
                width: '100%',
                top: 0,
              }
            : {}
        }
      >
        <div className={pathName === '/tin-tuc-su-kien' ? ( offset >= 100 ? "l-header-mobile__inner background_header" : "l-header-mobile__inner") : "l-header-mobile__inner background_header"}>
          <div
            className="l-header-mobile__inner__nav"
            onClick={() => {
              clickOpenMenu(!openMenu);
            }}
          >
            <span>
              <img src="/assets/img/common/icn_nav_01.svg" alt="" />
            </span>
          </div>
          <div className="l-header-mobile__inner__logo">
            <a href="/">
              <Image
                src="/assets/img/common/fig_logo_hh.svg"
                width={50}
                height={55}
                style={{ width: '50px' }}
                alt=""
              />
            </a>
          </div>
          <div
            className="l-header-mobile__inner__cart"
            onClick={onClickCheckOut}
          >
            <span>
              <img src="/assets/img/common/icn_cart_01.svg" alt="" />
              {infoUser && carts && carts?.OrderProducts?.length ? (
                <i className="l-app-header__inner__user__count">
                  {carts?.OrderProducts?.length}
                </i>
              ) : null}
            </span>
          </div>
        </div>
      </header>

      <div
        className={
          openMenu
            ? 'c-mobile-navigation c-mobile-navigation-open'
            : 'c-mobile-navigation'
        }
      >
        <div
          className="c-mobile-navigation__transaction"
          style={openMenu ? { display: 'block' } : { display: 'none' }}
        />
        <div className="c-mobile-navigation__inner">
          <div
            className="c-mobile-navigation__inner__close"
            onClick={() => {
              clickOpenMenu(!openMenu);
            }}
          >
            <img src="/assets/img/common/icn_close_01.svg" alt="" />
          </div>
          <div className="c-mobile-navigation__inner__logo">
            <span
              onClick={() => {
                clickLinkMobile('/');
              }}
            >
              <Image
                src="/assets/img/common/fig_logo_hh.svg"
                width={50}
                height={55}
                style={{ width: '50px' }}
                alt=""
              />
            </span>
          </div>
          <div className="c-mobile-navigation__inner__menu">
            <div
              className="c-mobile-navigation__inner__menu__scroll c-mobile-navigation__main"
              style={
                openSubMenu === '' ? { display: 'block' } : { display: 'none' }
              }
            >
              <ul>
                {!infoUser ? (
                  <li className="c-mobile-navigation__inner__menu__sign">
                    <span
                      className="btn-dk"
                      onClick={() => {
                        clickOpenMenu(!openMenu);
                        setState(2);
                        router.push('/');
                      }}
                    >
                      {trans.header.sign}
                    </span>{' '}
                    /{' '}
                    <span
                      className="btn-dk"
                      onClick={() => {
                        clickOpenMenu(!openMenu);
                        setState(1);
                      }}
                    >
                      {trans.header.log}
                    </span>
                  </li>
                ) : (
                  <li className="c-mobile-navigation__inner__menu__signed">
                    <a href="/dashboard/gold">
                      <img className="avatar" src={infoUser.avatar} alt="" />
                    </a>
                    <div className="l-header__setting__logined__drop">
                      <ul>
                        <li>
                          <a href="/dashboard/gold">
                            <div>{trans.header.mana}</div>
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard/other">
                            <div>{trans.header.setting}</div>
                          </a>
                        </li>
                        {/* <li>
                          <Link
                            href={
                              infoUser?.kyc_verify === false
                                ? '/dashboard/other/mess-kyc'
                                : '/dashboard/other/security'
                            }
                          >
                            <div>{trans.header.se}</div>
                          </Link>
                        </li> */}
                      </ul>
                      <button onClick={onLogout} type="button">
                        {trans.header.out}
                      </button>
                    </div>
                  </li>
                )}

                {arr_header?.map((data, index) => {
                  return (
                    <li key={index}>
                      <span
                        className="c-mobile-navigation__active-sp"
                        onClick={() => {
                          setOpenSubMenu({
                            sub_header: data?.sub_header,
                            current: data?.title,
                          });
                        }}
                      >
                        {data?.title}
                        <img
                          src="/assets/img/common/icn_arrow-right_01.svg"
                          alt=""
                          className='arrow_header_next'
                        />
                      </span>
                    </li>
                  );
                })}

                <li className="hover_country">
                  <div className="modal_lang">
                    <div style={{cursor:'pointer'}} onClick={changeLanguageVi}>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}
                      >
                        <img
                          src="/assets/img/home/VN_i.svg?t=1"
                          alt="ico"
                          className="icon_country"
                          style={{
                            width: '24px',
                            marginRight: '5px',
                          }}
                        />
                        {trans.header.vi}
                      </span>
                    </div>

                    <div style={{cursor:'pointer'}} onClick={changeLanguageEn}>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}
                      >
                        <img
                          src="/assets/img/home/EN_i.svg?t=1"
                          alt="ico"
                          className="icon_country"
                          style={{
                            width: '24px',
                            marginRight: '5px',
                          }}
                        />
                        {trans.header.en}
                      </span>
                    </div>
                  </div>
                  <div
                    className="c-mobile-navigation__active-sp tag__language"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {' '}
                    <img
                      src={
                        router.locale === 'vi'
                          ? '/assets/img/home/VN_i.svg?t=1'
                          : '/assets/img/home/EN_i.svg?t=1'
                      }
                      alt="ico"
                      className="icon_country"
                      style={{
                        width: '28px',
                      }}
                    />
                    {router.locale === 'en' ? trans.header.en : trans.header.vi}
                    <img
                      src="/assets/img/common/icn_arrow-right_01.svg"
                      alt=""
                      className='arrow_header_next'
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="c-mobile-navigation__inner__menu__scroll c-mobile-navigation__dt"
              style={
                openSubMenu?.sub_header
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <ul>
                <li className="c-mobile-navigation__inner__menu__back">
                  <a
                    onClick={() => {
                      if (
                        checkHeader === 'Ứng dụng di động' ||
                        checkHeader === 'Quà tặng doanh nghiệp'
                      ) {
                        setOpenSubMenu({
                          sub_header: arr_header[0]?.sub_header,
                          current: arr_header[0]?.title,
                        });

                        setCheckHeader('');
                      } else {
                        setOpenSubMenu('');
                      }
                    }}
                  >
                    <img
                      src="/assets/img/common/icn_arrow-left_01.svg"
                      alt=""
                    />{' '}
                    {openSubMenu?.current}
                  </a>
                </li>
                {openSubMenu?.sub_header?.map((data, index) => {
                  return (
                    <>
                      <li
                        onClick={() => {
                          if (data?.sub_header.length > 0) {
                            setCheckHeader(data?.title);
                            setOpenSubMenu({
                              sub_header: data?.sub_header,
                              current: data?.title,
                            });
                          } else {
                            clickLinkMobile(data?.path);
                          }
                        }}
                      >
                        <div>
                          {data?.title}
                          {data?.sub_header?.length > 0 && (
                            <img
                              src="/assets/img/common/icn_arrow-right_01.svg"
                              alt=""
                              className='arrow_header_next'
                            />
                          )}
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="c-mobile-navigation__inner__app">
            <p>{trans.header.text_m_h}</p>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.hana.gold"
                className="relative"
              >
                <img src="/assets/img/common/icn_google-play_01.svg" alt="" />
                <p className="absolute">Get it on Google Play</p>
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/vn/app/hanagold/id1611748758"
                className="relative"
              >
                <img src="/assets/img/common/icn_appstore_01a.svg" alt="" />
                <p className="absolute">Download on the App Store</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* End header mobile */}
      {/* Header */}
      <header
        className={offset >= 200 ? 'l-header darkHeader' : 'l-header'}
        style={
          router.pathname &&
          [
            '/dai-su-kinh-doanh-vang',
            '/app-mua-vang-online',
            '/dong-vang-cong-nghe-hanagold',
            '/doi-tac',
            '/gia-vang',
            '/nhuong-quyen-kinh-doanh-vang',
            '/ty-gia-ngoai-te',
            '/nhuong-quyen-kinh-doanh-vang/[id]',
          ].includes(router.pathname)
            ? {
                position: 'fixed',
                width: '100%',
                top: 0,
              }
            : {}
        }
      >
        <div className="l-header__inner">
          <div className="l-header__logo">
            { router.pathname === '/' ? (
              <h1>
                <a href="/" title='HanaGold - Đầu tư và tích lũy vàng công nghệ NFC'>
                  <div>
                    <span className='is-hidden'>HanaGold - Đầu tư và tích lũy vàng công nghệ NFC</span>
                    <Image
                      src="/assets/img/common/fig_logo_hh.svg"
                      width={86}
                      height={94}
                      alt='HanaGold - Đầu tư và tích lũy vàng công nghệ NFC'
                    />
                  </div>
                </a>
              </h1>
            ) : (
              <a href="/" title='HanaGold - Đầu tư và tích lũy vàng công nghệ NFC'>
                <div>
                  <Image
                    src="/assets/img/common/fig_logo_hh.svg"
                    width={86}
                    height={94}
                    alt='HanaGold - Đầu tư và tích lũy vàng công nghệ NFC'
                  />
                </div>
              </a>
            ) }
          </div>

          <div className="l-header__navigation">
            <div className="cssmenu">
              <ul>
                {arr_header?.map((data, index) => {
                  return (
                    <li
                      className={
                        ['/san-pham', '/product'].includes(router.pathname)
                          ? 'has-sub '
                          : 'has-sub'
                      }
                      key={index}
                    >
                      {/* <Link href={data?.path}>
                        <a
                          className={
                            data?.sub_header?.find(
                              it => it?.path === pathName
                            )
                              ? 'active_header_new'
                              : ''
                          }
                        >
                          <span>
                            {data?.title}
                            <img
                              src="/assets/img/common/icn_arrow_nav_down.svg"
                              alt=""
                            />
                          </span>
                        </a>
                      </Link> */}
                        <div
                          className={
                            data?.sub_header?.find(
                              it => it?.path === pathName
                            )
                              ? 'header-menu active_header_new'
                              : 'header-menu'
                          }
                        >
                          <span>
                            {data?.title}
                            <img
                              src="/assets/img/common/icn_arrow_nav_down.svg"
                              alt=""
                            />
                          </span>
                        </div>
                      <ul className="sub_header_a">
                        {data?.sub_header?.map((sub_data, sub_idx) => {
                          return (
                            <li key={sub_idx}>
                              { sub_data.path ? (
                                <Link
                                  href={sub_data?.path}
                                  target={sub_data?.other ? '_blank' : '_self'}
                                >
                                  <a
                                    target={sub_data?.other ? '_blank' : '_self'}
                                  >
                                    <span>{sub_data?.title}</span>
                                  </a>
                                </Link>
                              ) : (
                                <span className='submenu-nolink'>{sub_data?.title}</span>
                              )}
                              
                              {sub_data?.sub_header?.length > 0 && (
                                <ul className="sub_header_b">
                                  {sub_data?.sub_header?.map(
                                    (sub_data_a, sub_idx_a) => {
                                      return (
                                        <li key={sub_idx_a}>
                                          <Link href={sub_data_a?.path}>
                                            <div>
                                              <span className="sub_header_b-title">{sub_data_a?.title}</span>
                                            </div>
                                          </Link>
                                        </li>
                                      );
                                    },
                                  )}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}

                <li className="has-sub hover_country">
                  <div className="modal_lang">
                    <div style={{cursor:'pointer'}} onClick={changeLanguageVi}>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}
                      >
                        <img
                          src="/assets/img/home/VN_i.svg?t=1"
                          alt="ico"
                          className="icon_country"
                          style={{
                            width: '24px',
                            marginRight: '5px',
                          }}
                        />
                        {trans.header.vi}
                      </span>
                    </div>

                    <div style={{cursor:'pointer'}} onClick={changeLanguageEn}>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}
                      >
                        <img
                          src="/assets/img/home/EN_i.svg?t=1"
                          alt="ico"
                          className="icon_country"
                          style={{
                            width: '24px',
                            marginRight: '5px',
                          }}
                        />
                        {trans.header.en}
                      </span>
                    </div>
                  </div>

                  <div className='tag__language'>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={
                          router.locale === 'vi'
                            ? '/assets/img/home/VN_i.svg?t=1'
                            : '/assets/img/home/EN_i.svg?t=1'
                        }
                        alt="ico"
                        className="icon_country"
                        style={{
                          width: '28px',
                          marginRight: '5px',
                        }}
                      />
                      {router.locale === 'en'
                        ? trans.header.en
                        : trans.header.vi}
                      <img
                        src="/assets/img/common/icn_arrow_nav_down.svg"
                        alt=""
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="l-header__setting">
            <ul>
              <li style={{ position: 'relative' }}>
                <div style={{ position: 'relative' }} onClick={onClickCheckOut}>
                  <img src="/assets/img/common/icn_cart_01.svg" alt="" />
                  {infoUser && carts?.OrderProducts?.length > 0 ? (
                    <i className="l-app-header__inner__user__count">
                      {carts?.OrderProducts?.length}
                    </i>
                  ) : null}
                </div>
              </li>
              <li
                className={
                  !infoUser
                    ? 'l-header__setting__login'
                    : 'l-header__setting__logined'
                }
              >
                {infoUser ? (
                  <div ref={thisRefSettingAccount}>
                    <a onClick={onCickShowSettingAccount}>
                      <img className="avatar" src={infoUser.avatar} alt="" />
                    </a>
                    <div
                      className={
                        showSettingAccount
                          ? 'l-header__setting__logined__drop l-header__setting__logined__drop-open'
                          : 'l-header__setting__logined__drop'
                      }
                    >
                      <ul>
                        <li>
                          <a href="/dashboard/gold">
                            <div> {trans.header.mana}</div>
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard/other">{trans.header.setting}</a>
                        </li>
                        {/* <li>
                          <Link
                            href={
                              infoUser?.kyc_verify === false
                                ? '/dashboard/other/mess-kyc'
                                : '/dashboard/other/security'
                            }
                          >
                            <div>{trans.header.se}</div>
                          </Link>
                        </li> */}
                      </ul>
                      <button onClick={onLogout} type="button">
                        {trans.header.out}
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <span
                      className="btn-dk"
                      onClick={() => {
                        setState(2);
                        router.push('/');
                      }}
                    >
                      {trans.header.sign}
                    </span>{' '}
                    /{' '}
                    <span
                      className="btn-dk"
                      onClick={() => {
                        setState(1);
                      }}
                    >
                      {trans.header.log}
                    </span>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </header>
      {/* End Header */}
      <Login state={showLogin} setState={setState} />
    </div>
  );
};

export default Header;
