
/* eslint-disable jsx-quotes */
import { wrapper } from '../redux/store';
import 'antd/dist/antd.css';
import Layout from '../components/Layout';
import '../../assets/css/slick.css';
import 'animate.css';
import '../../assets/css/style.css';
import '../../assets/css/about.css';
import '../../assets/css/franchise.css';
import '../../assets/css/home.css';
import '../../assets/css/style-app.css';
import '../../assets/css/style-custom.css';
import NextNProgress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import 'lazysizes';
import AOS from 'aos';
import 'aos/dist/aos.css';
import useTrans from '@/hooks/useTrans';

/**
 * React hook that forces a scroll reset to a particular set of coordinates in the document
 * after `next/router` finishes transitioning to a new page client side. It smoothly scrolls back to
 * the top by default.
 *
 * @see https://github.com/vercel/next.js/issues/3249
 * @see https://github.com/vercel/next.js/issues/15206
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ScrollToOptions
 * @param {ScrollOptions} [options={}] Hook options.
 * @param {ScrollBehavior} [options.behavior='smooth'] Specifies whether the scrolling should animate smoothly,
 *  or happen instantly in a single jump.
 * @param {number} [options.left=0] Specifies the number of pixels along the X axis to scroll the window.
 * @param {number} [options.top=0] Specifies the number of pixels along the Y axis to scroll the window.
 */
function useRouterScroll({ behavior = 'smooth', left = 0, top = 0 } = {}) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeComplete = url => {
      if (url && url.includes('/news/category')) {
        return;
      }
      window.scrollTo({ top, left, behavior });
      document.body.scrollTop = top;
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe from the event
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [behavior, left, top]);
}

function App({ Component, pageProps }) {
  const trans = useTrans();

  useEffect(() => {
    AOS.init();
  }, []);
  const getLayout = Component.getLayout || (page => page);
  useRouterScroll();
  if (Component.getLayout) {
    return getLayout(
      <>
        <NextNProgress color="#d0ab6d" />
        <Component {...pageProps} />
      </>,
    );
  }
  return (
    <Layout>
      <NextNProgress color="#d0ab6d" />
      <Component {...pageProps}/>
    </Layout>
  );
}

export default wrapper.withRedux(App);
